.account {
  display: flex;
  flex-direction: column;

  section {
    border: solid black 2px;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;

    button {
      background: none;
      border: solid black 2px;
      padding: 0.7rem;
      border-radius: 0.5rem;
      font-size: 1.3rem;

      &:hover {
        background-color: black;
        color: white;
        transition: ease-in-out 500ms;
      }
    }

    &.danger {
      border: dashed red 1px;

      button {
        border-color: red;
        color: red;
        &:hover {
          background-color: red;
          color: white;
          font-weight: 800;
        }
      }
    }
  }

  .small-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    section {
      width: 48.5%;
    }
  }

  h1 {
    font-size: 3rem;
    margin-top: 2rem;
  }
  p {
    font-size: 1.5rem;

    &:first-of-type {
      margin-bottom: 1rem;
    }
  }
  h3 {
    font-size: 2rem;
  }
}
